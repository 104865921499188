const init = () => {
  // load gtag.js:  https://developers.google.com/analytics/devguides/collection/gtagjs
  const script1 = document.createElement('script')
  script1.src = `https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID}`
  script1.async = true
  document.body.appendChild(script1)

  const script2 = document.createElement('script')
  script2.text = `window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', '${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID}');`
  document.body.appendChild(script2)
}

const logPageView = (url: string) => {
  if (!process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID) {
    return
  }

  window.gtag('config', process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID, {
    page_path: url,
  })
}

export default { init, logPageView }
