import { ApolloClient, HttpLink, InMemoryCache } from '@apollo/client'
import { useMemo } from 'react'

// biome-ignore lint/suspicious/noExplicitAny: <explanation>
let apolloClient: ApolloClient<any>

const createApolloClient = () =>
  new ApolloClient({
    ssrMode: typeof window === 'undefined',
    link: new HttpLink({
      uri: process.env.NEXT_PUBLIC_API_URL,
    }),
    cache: new InMemoryCache({}),
  })

export const initializeApollo = (
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  initialState: ApolloClient<any> | null = null
) => {
  const localApolloClient = apolloClient ?? createApolloClient()

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = localApolloClient.extract()
    // Restore the cache using the data passed from getStaticProps/getServerSideProps
    // combined with the existing cached data
    localApolloClient.cache.restore({ ...existingCache, ...initialState })
  }
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') {
    return localApolloClient
  }
  // Create the Apollo Client once in the client
  if (!apolloClient) {
    apolloClient = localApolloClient
  }

  return localApolloClient
}

// biome-ignore lint/suspicious/noExplicitAny: <explanation>
export const useApollo = (initialState: ApolloClient<any>) => {
  const store = useMemo(() => initializeApollo(initialState), [initialState])
  return store
}
